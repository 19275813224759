import {List, Map} from 'immutable';


const getDomainState = (state) => {

    return state.get('auth', Map());
};


export const isAuthenticated = (state) => {

    return getDomainState(state)
        .get('isAuthenticated', false);
};


export const getUser = (state) => {

    return getDomainState(state)
        .get('user', null);
};


export const hasRole = (state, roleToCheck) => {

    const user = getUser(state);
    if (user === null) {

        return false;
    }

    return user.get('roles', List())
        .includes(roleToCheck);
};
