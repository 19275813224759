import config from '../../../config';

import * as apiConstants from '../apiConstants';
import * as dataUtils from '../../../utils/dataUtils';

import ApiRequest from './ApiRequest';
import PaginationApiResponse from '../Response/Pagination/PaginationApiResponse';


/**
 * Builder API to fluently create API requests. Supports endpoint specific configuration.
 * Modified builder pattern to pass data directly to building object instead of local holding.
 *
 * @see src/config/config.default.js : config.endpoints
 */
class ApiRequestBuilder {

    /**
     * @type {object} Endpoint configuration storing object
     */
    endpointConfiguration;


    /**
     * @type {object|null} Pagination data
     */
    pageData = null;


    /**
     * @type {ApiRequest}
     */
    request;


    /**
     * @param method {string}
     * @param path {string}
     * @param endpoint {string|null} Endpoint identifier as in configuration, defaults to 'backend'
     * @private
     */
    constructor(method, path, endpoint = 'backend') {

        this.request = new ApiRequest(path, method);

        return this.endpoint(endpoint)
            .method(method)
            .path(path);
    }


    /**
     * @param body {string|object|any} Any payload that can be serialized
     * @returns {ApiRequestBuilder}
     */
    body(body) {

        this.request.setBody(body);

        return this;
    }


    /**
     * @param method {string} HTTP method
     * @returns {ApiRequestBuilder}
     */
    method(method) {

        this.request.setMethod(method);

        return this;
    }


    /**
     * @param path {string} Request path with path variable placeholders. Supported place holders are:
     *                        1. /path/:varName/anotherPathSegment
     *                        2. /path/{varName}/anotherPathSegment
     * @returns {ApiRequestBuilder}
     */
    path(path) {

        this.request.setPath(path);

        return this;
    }


    /**
     * @param name {string}
     * @param value {string|any}
     * @returns {ApiRequestBuilder}
     */
    pathParam(name, value) {

        this.request.setPathVariable(name, value);

        return this;
    }


    /**
     * @param name {string}
     * @param value {string|any}
     * @returns {ApiRequestBuilder}
     */
    queryParam(name, value) {

        this.request.setQueryParameter(name, value);

        return this;
    }


    /**
     * @param dispatchType {string|number}
     * @param callback {function}
     * @return {ApiRequestBuilder}
     */
    dispatch(dispatchType, callback) {

        this.request.addDispatch(dispatchType, callback);

        return this;
    }


    /**
     * @param callback {function} Function called when the request is being started
     * @returns {ApiRequestBuilder} Builder instance
     */
    onInit(callback) {

        return this.dispatch(apiConstants.REQUEST_INIT, callback);
    }


    /**
     * @param callback {function} Function called if anything technical goes wrong with the request e.g. not reachable
     * @returns {ApiRequestBuilder} Builder instance
     */
    onFailure(callback) {

        return this.dispatch(apiConstants.REQUEST_FAILURE, callback);
    }


    /**
     * @param callback {function} Function called if the status code is any 2xx
     * @returns {ApiRequestBuilder} Builder instance
     */
    onSuccess(callback) {

        return this.dispatch(apiConstants.REQUEST_SUCCESS, callback);
    }


    /**
     * @param httpStatus {number} Http status code to be called on
     * @param callback {function} Function called if the status code is any 2xx
     * @returns {ApiRequestBuilder} Builder instance
     */
    onStatus(httpStatus, callback) {

        return this.dispatch(httpStatus.toString(10), callback);
    }


    /**
     * Convenience builder method
     *
     * @returns {ApiRequestBuilder} Content-Type application/json configured api request
     */
    json() {

        return this.header(apiConstants.HTTP_HEADER_CONTENT_TYPE, apiConstants.HTTP_HEADER_CONTENT_TYPE_JSON);
    }


    /**
     * @param endpoint {string} Endpoint identifier
     * @returns {ApiRequestBuilder}
     */
    endpoint(endpoint) {

        const endpointConfiguration = config.endpoints[endpoint];

        if (!endpointConfiguration) {

            throw new Error('Endpoint configuration for `' + endpoint + '` not configured');
        }

        this.endpointConfiguration = endpointConfiguration;

        return this;
    }


    /**
     * @returns {ApiRequest}
     */
    build() {

        const url = this.buildUrl(this.request);
        this.request.setUrl(url);

        // in case there is pagination data, automatic wrapping of success callbacks
        if (this.pageData) {

            const successDispatches = this.request.getDispatches(apiConstants.REQUEST_SUCCESS);
            for (let i = 0; i < successDispatches.length; i++) {
                const originalSuccessDispatch = successDispatches[i];
                successDispatches[i] = (dispatch, request, response) => {

                    const paginationApiResponse = new PaginationApiResponse(response);

                    originalSuccessDispatch(dispatch, request, paginationApiResponse);
                };
            }
        }

        return this.request;
    }


    /**
     * @param {ApiRequest} request
     * @return {string} Final request URL
     * @private
     */
    buildUrl(request) {

        return this.buildBaseUrl()
            + this.buildPath(request.getPath(), request.getPathVariables())
            + this.buildQueryString(request.getQueryParameters());
    }


    /**
     * @returns {string}
     * @private
     */
    buildBaseUrl() {

        return this.endpointConfiguration.host;
    }


    /**
     * @param path {string} Request path including possible path variable placeholders
     * @param pathVariables {object} Path variables
     * @returns {string} Parsed path
     */
    buildPath(path, pathVariables) {

        Object.keys(pathVariables).forEach((pathVariableName) => {

            const pathVariableValue = pathVariables[pathVariableName];

            // supporting both styles of path variable declaration
            path = path.replace(':' + pathVariableName, pathVariableValue);
            path = path.replace('{' + pathVariableName + '}', pathVariableValue);
        });

        return path;
    }


    /**
     * @param parameters
     * @returns {string}
     */
    buildQueryString = (parameters) => {

        let queryStrings = [];

        Object.keys(parameters).forEach((parameterName) => {

            queryStrings.push(ApiRequestBuilder.createQueryParameter(parameterName, parameters[parameterName]));
        });

        // add pagination data if required
        queryStrings = this.buildPaginationQueryString(queryStrings);

        if (queryStrings.length === 0) {

            return '';
        }

        return '?' + queryStrings.join('&');
    };


    /**
     * Creates a single name=value pair for the query-string
     *
     * @param name {string} Name
     * @param value {string} Value
     * @param encodeValue {boolean} True encodes the value; false not
     * @returns {string} Key-value-pair URI encoded
     */
    static createQueryParameter(name, value, encodeValue = true) {

        const queryStringValue = encodeValue ? encodeURIComponent(value) : value;

        return encodeURIComponent(name) + '=' + queryStringValue;
    }


    /**
     * @param queryParameters {array} Query string pairs
     * @returns {array} Query string pairs with potential pagination
     */
    buildPaginationQueryString(queryParameters) {

        const pageData = this.pageData;
        if (!pageData) {

            return queryParameters;
        }

        const simplePaginationParameters = {
            page: pageData.page,
            size: pageData.size
        };

        Object.keys(simplePaginationParameters).forEach((pageDataKey) => {

            const pageDataValue = pageData[pageDataKey];
            if (!dataUtils.isSomething(pageDataValue)) {

                return;
            }

            queryParameters.push(ApiRequestBuilder.createQueryParameter(pageDataKey, pageDataValue));
        });

        const sortValues = [];
        let lastDirection = null;

        dataUtils.each(pageData.sort, (sortDirection, property) => {

            sortValues.push(property);
            lastDirection = sortDirection;
        });

        if (sortValues.length > 0) {
            sortValues.push(lastDirection);
            queryParameters.push(ApiRequestBuilder.createQueryParameter('sort', sortValues.join(','), false));
        }

        return queryParameters;
    }


    /**
     * @param name {string} Header name
     * @param value {string} Any header value
     * @return {ApiRequestBuilder}
     */
    header(name, value) {

        this.request.setHeader(name, value);

        return this;
    }


    /**
     * Creates a pagination request when built
     *
     * @param pageData {object} Page data
     * @returns {ApiRequestBuilder}
     */
    withPagination(pageData) {

        this.pageData = pageData;

        return this;
    }


    /**
     * @param path {string} Path supporting place holders
     * @param endpoint {string} Optional endpoint identifier
     * @returns {ApiRequestBuilder}
     */
    static delete(path, endpoint) {

        return new ApiRequestBuilder(apiConstants.HTTP_METHOD_DELETE, path, endpoint);
    }


    /**
     * @param path {string} Path supporting place holders
     * @param endpoint {string} Optional endpoint identifier
     * @returns {ApiRequestBuilder}
     */
    static get(path, endpoint) {

        return new ApiRequestBuilder(apiConstants.HTTP_METHOD_GET, path, endpoint);
    }


    /**
     * @param path {string} Path supporting place holders
     * @param endpoint {string} Optional endpoint identifier
     * @returns {ApiRequestBuilder}
     */
    static head(path, endpoint) {

        return new ApiRequestBuilder(apiConstants.HTTP_METHOD_HEAD, path, endpoint);
    }


    /**
     * @param path {string} Path supporting place holders
     * @param endpoint {string} Optional endpoint identifier
     * @returns {ApiRequestBuilder}
     */
    static patch(path, endpoint) {

        return new ApiRequestBuilder(apiConstants.HTTP_METHOD_PATCH, path, endpoint);
    }


    /**
     * @param path {string} Path supporting place holders
     * @param endpoint {string} Optional endpoint identifier
     * @returns {ApiRequestBuilder}
     */
    static put(path, endpoint) {

        return new ApiRequestBuilder(apiConstants.HTTP_METHOD_PUT, path, endpoint);
    }


    /**
     * @param path {string} Path supporting place holders
     * @param endpoint {string} Optional endpoint identifier
     * @returns {ApiRequestBuilder}
     */
    static post(path, endpoint) {

        return new ApiRequestBuilder(apiConstants.HTTP_METHOD_POST, path, endpoint);
    }
}


export default ApiRequestBuilder;
