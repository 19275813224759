import {List, Map} from 'immutable';


import * as metaStateUtils from '../../../store/util/metaStateUtils';

import * as configurationConstants from '../configurationConstants';
import saveConfigurationReducer from './saveConfigurationReducer';


const initialState = Map({

    countries: Map(),

    valueUnits: List()

}).merge(metaStateUtils.getDefaultMetaState());


function reducers(state = initialState, action) {

    switch (action.type) {


        case configurationConstants.CONFIGURATION_LOADED:

            return saveConfigurationReducer(state, action.payload);


        default:

            return state;
    }
}


export default reducers;
