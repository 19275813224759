import ApiResponse from '../ApiResponse';


class PaginationApiResponse extends ApiResponse {


    /**
     * @param apiResponse {ApiResponse}
     */
    constructor(apiResponse) {

        super(apiResponse.getStatusCode());

        this.setHeaders(apiResponse.getHeaders());
        this.setBody(apiResponse.getBody());
    }


    /**
     * @param defaultBody {any} In case wrong pagination has been requested e.g. out of scope
     * @return {array} Actual data; undefined in case the response has no content property
     */
    getContent(defaultBody = undefined) {

        const fullBody = this.getBody();
        if (!fullBody.hasOwnProperty('content')) {

            return defaultBody || [];
        }

        return fullBody.content;
    }


    /**
     * @return {number} Index of current page (page1 = 0)
     */
    getCurrentPageIndex() {

        return this.getPage().pageNumber;
    }


    /**
     * @return {number} Elements per page
     */
    getSize() {

        return this.getPage().pageSize;
    }


    /**
     * @returns {number}
     */
    getTotalElements() {

        return this.getBody().totalElements;
    }


    /**
     * @returns {number}
     */
    getTotalPages() {

        return this.getBody().totalPages;
    }


    /**
     * @returns {boolean} True if no elements; false if at least one element
     */
    isEmpty() {

        return this.getTotalElements() === 0;
    }


    /**
     * @returns {boolean} True if the current page is the last page; false otherwise
     */
    isLastPage() {

        const totalPages = this.getTotalPages();

        return totalPages === 0 || (this.getCurrentPageIndex() + 1) === totalPages;
    }


    /**
     * @returns {{}}
     * @private
     */
    getPage = () => {

        return this.getBody().pageable;
    };


    /**
     * @return {PaginationApiResponse} An empty API pagination response
     */
    static empty() {

        const apiResponse = new ApiResponse(200);
        apiResponse.setBody({
            content: [],
            pageable: {
                pageNumber: 0,
                pageSize: 20
            },
            totalPages: 0,
            totalElements: 0
        });

        return new PaginationApiResponse(apiResponse);
    }
}


export default PaginationApiResponse;
