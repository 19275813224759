import React, {Component} from 'react';
import styles from './SourceChartComponent.scss';
import * as d3 from 'd3';
import PropTypes from 'prop-types';

class SourceChartComponent extends Component {
  static propTypes = {
    data: PropTypes.any.isRequired
  };
  componentDidMount() {
    generateSourcePie(this.pieContainer, this.props.data);
  }
  componentDidUpdate() {
    d3.select('svg').remove();
    generateSourcePie(this.pieContainer, this.props.data);
  }

  render() {
    return (
      <div ref={ref => (this.pieContainer = ref)} className={styles['source-chart'] + ' ' + styles['left-standard-margin']}>
        <h3 className={styles['charging-source-heading'] + ' font-white text-left font-face-regular'}>CHARGING<br />SOURCE</h3>
      </div>
    );
  }
}

function generateSourcePie(pieContainer, data) {
  var config = {
    svgWidth: 380,
    svgHeight: 380
  };

  config.outerRadius = config.svgWidth / 2 - 10;

  var width = config.svgWidth,
    height = config.svgHeight,
    pieRadDiff = 10,
    pieStrokeWidth = 5,
    linePieStrokeWidth = 1,
    radius = Math.min(width, height) / 2 - (pieRadDiff + 1) * data.length;

  //CREATE ARC
  var arc = d3
    .arc()
    .innerRadius(function (d, i) {
      return radius + (i + 1) * pieRadDiff - pieStrokeWidth;
    })
    .outerRadius(function (d, i) {
      return radius + (i + 1) * pieRadDiff;
    });

  var arcLine = d3
    .arc()
    .innerRadius(function (d, i) {
      return radius - 10 + (i + 1) * pieRadDiff - linePieStrokeWidth;
    })
    .outerRadius(function (d, i) {
      return radius - 10 + (i + 1) * pieRadDiff;
    });

  var radialLineArc = d3
    .arc()
    .outerRadius(function (d) {
      return radius - 10 + (d.index + 1) * pieRadDiff - linePieStrokeWidth;
    })
    .innerRadius(function (d) {
      return radius - 10 + (d.index + 1) * pieRadDiff;
    });

  var pie = d3
    .pie()
    .startAngle(0.7 * Math.PI)
    .endAngle(2.3 * Math.PI)
    .sort(null)
    .value(function (d) {
      return d.value;
    });

  //CREATE SVG CONTAINER
  var svg = d3
    .select(pieContainer)
    .append('svg')
    .attr('width', width)
    .attr('height', height)
    .append('g')
    .attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')');

  //CREATE ANOTHER GROUP FOR THIN LINE
  var gLine = svg
    .selectAll('.arc-line')
    .data(pie([{value: 1}]))
    .enter()
    .append('g')
    .attr('class', styles['arc-line']);

  //DRAW INNER THIN PATH
  gLine
    .append('path')
    .attr('d', arcLine)
    .attr('class', styles['arc-path']);

  //CREATE GROUP FOR ARCS
  var g = svg
    .selectAll('.arc')
    .data(pie(data))
    .enter()
    .append('g')
    .attr('class', styles.arc);

  //DRAW PATH FOR EACH ARCS
  g.append('path')
    .attr('d', arc)
    .attr('class', styles['arc-path']);

  //DRAW RADIUS LINES
  g.append('line')
    .attr('x1', function (d) {
      var radius = radialLineArc.outerRadius()(d);
      var endAngle = radialLineArc.endAngle()(d) - 90 * Math.PI / 180;
      return radius * Math.cos(endAngle);
    })
    .attr('y1', function (d) {
      var radius = radialLineArc.outerRadius()(d);
      var endAngle = radialLineArc.endAngle()(d) - 90 * Math.PI / 180;
      return radius * Math.sin(endAngle);
    })
    .attr('x2', function (d) {
      var radius = radialLineArc.outerRadius()(d) - (120 - d.index * 30);
      var endAngle = radialLineArc.endAngle()(d) - 90 * Math.PI / 180;
      return radius * Math.cos(endAngle);
    })
    .attr('y2', function (d) {
      var radius = radialLineArc.outerRadius()(d) - (120 - d.index * 30);
      var endAngle = radialLineArc.endAngle()(d) - 90 * Math.PI / 180;
      return radius * Math.sin(endAngle);
    })
    .attr('class', styles['arc-lines']);

  //DRAW TEXTS
  g.append('text')
    .attr('transform', function (d) {
      var radius = radialLineArc.outerRadius()(d) - (130 - d.index * 30);
      var endAngle = radialLineArc.endAngle()(d) - 90 * Math.PI / 180;
      var x = radius * Math.cos(endAngle) + d.index * 10;
      var y = radius * Math.sin(endAngle);
      return 'translate(' + [x, y] + ')';
    })
    .attr('dy', '.35em')
    .text(function (d) {
      return d.data.label;
    })
    .attr('class', styles['arc-label'] + ' font-face-light');
}

export default SourceChartComponent;
