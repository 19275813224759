import React, {Component} from 'react';
import styles from './NavigationComponent.scss';

class NavigationComponent extends Component {
  render() {
    return (
      <div>
        <img className={styles['map-trigger'] + ' full-width'} src='images/map_screenshot.jpg' alt='map' />
      </div>
    );
  }
}

export default NavigationComponent;
