import * as apiConstants from '../apiConstants';

import authService from '../../Auth';

import ApiRequestBuilder from './ApiRequestBuilder';


class OAuthTokenRequestBuilder {

    /**
     * @param path {string} Path supporting place holders
     * @param endpoint {string|null} Optional endpoint to use
     * @returns {ApiRequestBuilder}
     */
    static delete(path, endpoint = null) {

        return this.secure(ApiRequestBuilder.delete(path, endpoint));
    }


    /**
     * @param path {string} Path supporting place holders
     * @param endpoint {string|null} Optional endpoint to use
     * @returns {ApiRequestBuilder}
     */
    static get(path, endpoint = null) {

        return this.secure(ApiRequestBuilder.get(path, endpoint));
    }


    /**
     * @param path {string} Path supporting place holders
     * @param endpoint {string|null} Optional endpoint to use
     * @returns {ApiRequestBuilder}
     */
    static head(path, endpoint = null) {

        return this.secure(ApiRequestBuilder.head(path, endpoint));
    }


    /**
     * @param path {string} Path supporting place holders
     * @param endpoint {string|null} Optional endpoint to use
     * @returns {ApiRequestBuilder}
     */
    static patch(path, endpoint) {

        return this.secure(ApiRequestBuilder.patch(path, endpoint));
    }


    /**
     * @param path {string} Path supporting place holders
     * @param endpoint {string|null} Optional endpoint to use
     * @returns {ApiRequestBuilder}
     */
    static put(path, endpoint) {

        return this.secure(ApiRequestBuilder.put(path, endpoint));
    }


    /**
     * @param path {string} Path supporting place holders
     * @param endpoint {string|null} Optional endpoint to use
     * @returns {ApiRequestBuilder}
     */
    static post(path, endpoint) {

        return this.secure(ApiRequestBuilder.post(path, endpoint));
    }


    /**
     * @param apiRequestBuilder {ApiRequestBuilder} API request builder instance
     * @returns {ApiRequestBuilder} API request builder with token populated
     * @private
     */
    static secure(apiRequestBuilder) {

        apiRequestBuilder = this.addAuthorizationHeader(apiRequestBuilder);

        return this.addAuthRedirectFor401(apiRequestBuilder);
    }


    /**
     * @param apiRequestBuilder {ApiRequestBuilder} API request builder instance
     * @returns {ApiRequestBuilder} Builder instance
     */
    static addAuthorizationHeader(apiRequestBuilder) {

        const token = authService.getToken();

        if (!token) {

            return apiRequestBuilder;
        }

        const tokenType = token.token_type || '';
        const tokenTypeBearer = apiConstants.HTTP_HEADER_AUTHORIZATION_BEARER;
        if (tokenType.toLowerCase() !== tokenTypeBearer.toLowerCase()) {

            throw Error('Unknown token_type ' + tokenType);
        }

        const accessToken = token.access_token;
        if (!accessToken) {

            throw Error('No access token value in stored access token');
        }

        return apiRequestBuilder.header(apiConstants.HTTP_HEADER_AUTHORIZATION, `${tokenTypeBearer} ${accessToken}`);
    }


    /**
     * @param apiRequestBuilder {ApiRequestBuilder} API request builder instance
     */
    static addAuthRedirectFor401(apiRequestBuilder) {

        return apiRequestBuilder.dispatch(apiConstants.HTTP_STATUS_UNAUTHORIZED, () => authService.logout());
    }
}


export default OAuthTokenRequestBuilder;
