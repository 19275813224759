import 'babel-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';

import RootComponent from './components/RootComponent';
import configureStore from './store/configureStore';


import apiService from './services/Api';


configureStore((store) => {

    // service API binding to store
  apiService.setStore(store);
  ReactDOM.render(
      <RootComponent store={store}/>,
      document.getElementById('appContainer')
  );

});
