import {Map} from 'immutable';


import * as metaStateUtils from '../../../store/util/metaStateUtils';

import * as locationConstants from '../locationConstants';
import setLocationReducer from './setLocationReducer';
import setLocationsReducer from './setLocationsReducer';


const initialState = Map({

    selectedLocationId: null,
    locationsById: Map({})

}).merge(metaStateUtils.getDefaultMetaState());


function reducers(state = initialState, action) {

    switch (action.type) {


        case locationConstants.LOCATIONS_LOADED:

            return setLocationsReducer(state, action.payload);


        case locationConstants.LOCATION_LOADED:

            return setLocationReducer(state, action.payload);


        case locationConstants.ACTION_LOCATION_SELECT:

            return state.set('selectedLocationId', action.payload);


        default:

            return state;
    }
}


export default reducers;
