import * as apiConstants from '../Api/apiConstants';

import * as iamConstants from '../../components/Iam/iamConstants';

import OAuthTokenRequestBuilder from '../Api/Request/OAuthTokenRequestBuilder';

const API_BASE_PATH = '/api';

const API_PATH_OWN_USER_DETAILS = API_BASE_PATH + '/users/me';

const API_PATH_USERS = API_BASE_PATH + '/users';

const API_PATH_USER = API_BASE_PATH + '/users/{userId}';


class UserService {

    /**
     * @type {ApiService}
     */
    apiService;


    /**
     * @param apiService {ApiService}
     */
    constructor(apiService) {

        this.apiService = apiService;
    }


    getUserDetails(onSuccess, onFailure) {

        const apiRequest = OAuthTokenRequestBuilder.get(API_PATH_OWN_USER_DETAILS, apiConstants.ENDPOINT_AUTH)
            .json()
            .onSuccess((dispatch, request, response) => onSuccess(response.getBody()))
            .onFailure(onFailure)
            .build();

        this.apiService.execute(apiRequest);
    }


    getUsers() {

        const apiRequest = OAuthTokenRequestBuilder.get(API_PATH_USERS, apiConstants.ENDPOINT_AUTH)
            .json()
            .onInit((dispatch) => {

                dispatch({
                    type: iamConstants.ACTION_USERS_LOADING
                });
            })
            .onSuccess((dispatch, request, response) => {

                dispatch({
                    payload: response.getBody(),
                    type: iamConstants.ACTION_USERS_LOADED
                });
            })
            .build();

        this.apiService.execute(apiRequest);
    }


    getUser(userId) {

        const apiRequest = OAuthTokenRequestBuilder.get(API_PATH_USER, apiConstants.ENDPOINT_AUTH)
            .json()
            .pathParam('userId', userId)
            .onInit((dispatch) => {

                dispatch({
                    payload: userId,
                    type: iamConstants.ACTION_USER_LOADING
                });
            })
            .onSuccess((dispatch, request, response) => {

                dispatch({
                    payload: response.getBody(),
                    type: iamConstants.ACTION_USER_LOADED
                });
            })
            .build();

        this.apiService.execute(apiRequest);
    }


    saveUser(userData) {

        if (userData.id) {

            return this.updateUser(userData);
        }

        this.createUser(userData);
    }


    /**
     * @param userData {object} User data to be used
     */
    createUser(userData) {

        const apiRequest = OAuthTokenRequestBuilder.post(API_PATH_USERS, apiConstants.ENDPOINT_AUTH)
            .json()
            .body(userData)
            .onSuccess((dispatch, request, response) => UserService.onUserSaved(dispatch, response.getBody()))
            .build();

        this.apiService.execute(apiRequest);
    }


    /**
     * @param userData {object} User data to be used
     */
    updateUser(userData) {

        const apiRequest = OAuthTokenRequestBuilder.patch(API_PATH_USER, apiConstants.ENDPOINT_AUTH)
            .pathParam('userId', userData.id)
            .json()
            .body(userData)
            .onSuccess((dispatch, request, response) => UserService.onUserSaved(dispatch, response.getBody()))
            .build();

        this.apiService.execute(apiRequest);
    }


    /**
     * @param dispatch {function} Dispatch function
     * @param userData {object} Saved user data
     */
    static onUserSaved(dispatch, userData) {

        dispatch({
            payload: userData,
            type: iamConstants.ACTION_USER_SAVED
        });
    }
}


export default UserService;
