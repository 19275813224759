import {fromJS} from 'immutable';


import * as metaStateUtils from '../../../store/util/metaStateUtils';


export default (state, location) => {

    state = state.setIn(['locationsById', location.id], fromJS(location));

    return metaStateUtils.setIsLoaded(state);
};
