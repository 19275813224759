// ------ endpoints ---------------------------------------------------

export const ENDPOINT_AUTH = 'auth';

export const ENDPOINT_MANAGEMENT = 'management';

export const ENDPOINT_DEVICE_DATA = 'deviceData';


// ------ http headers ------------------------------------------------

export const HTTP_HEADER_AUTHORIZATION = 'Authorization';

export const HTTP_HEADER_AUTHORIZATION_BEARER = 'Bearer';


export const HTTP_HEADER_CONTENT_TYPE = 'Content-Type';

export const HTTP_HEADER_CONTENT_TYPE_JSON = 'application/json';
export const HTTP_HEADER_CONTENT_TYPE_FORM = 'application/x-www-form-urlencoded';


// ------ http methods ------------------------------------------------

export const HTTP_METHOD_DELETE = 'DELETE';

export const HTTP_METHOD_GET = 'GET';

export const HTTP_METHOD_HEAD = 'HEAD';

export const HTTP_METHOD_PATCH = 'PATCH';

export const HTTP_METHOD_POST = 'POST';

export const HTTP_METHOD_PUT = 'PUT';


// ------ htto status codes -------------------------------------------

export const HTTP_STATUS_OK = 200;
export const HTTP_STATUS_CREATED = 201;
export const HTTP_STATUS_NO_CONTENT = 204;

export const HTTP_STATUS_BAD_REQUEST = 400;
export const HTTP_STATUS_UNAUTHORIZED = 401;
export const HTTP_STATUS_FORBIDDEN = 403;
export const HTTP_STATUS_NOT_FOUND = 404;


// ------ request dispatch types --------------------------------------

// any status code non-succesful
export const REQUEST_FAILURE = 'REQUEST_FAILURE';

// dispatched before actual API communication starts
export const REQUEST_INIT = 'REQUEST_INIT';

// successful reception of response
export const REQUEST_SUCCESS = 'REQUEST_SUCCESS';


// ------ pagination --------------------------------------------------

export const PAGE_DEFAULT_PAGE = 0;

export const PAGE_SORT_SIZE = 10;

export const PAGE_SORT_DIR_ASC = 'asc';

export const PAGE_SORT_DIR_DESC = 'desc';
