import {applyMiddleware, compose, createStore} from 'redux';
import {createLogger} from 'redux-logger';
import thunkMiddleware from 'redux-thunk';

import config from '../config';

import devToolsComposer from './devToolsComposer';
import rootReducer from './reducer/rootReducer';


const isDevelopment = config.environment.isDevelopment;
const composer = isDevelopment ? devToolsComposer : compose;


/**
 * @param callback {function} Callback expecting the fully configured store
 */
export default function configureStore(callback) {

    const middlewares = [
        thunkMiddleware
    ];

    // logging is enabled on all environments for the time being
    if (isDevelopment) {

        const loggerMiddleware = createLogger({
            collapsed: (getState, action, logEntry) => !logEntry.error,
            predicate: (getState, action) => !action.type.includes('/')
        });

        middlewares.push(loggerMiddleware);
    }

    // middleware, store enhancements and finally creating the store object
    const appliedMiddleware = applyMiddleware(...middlewares);

    const storeCreator = composer(appliedMiddleware)(createStore);

    const store = storeCreator(rootReducer);

    callback(store);
}
