import React, {Component} from 'react';
import styles from './ChargingModeComponent.scss';

class ChargingModeComponent extends Component {
  render() {
    return (
      <div className={styles['charding-mode'] + ' ' + styles['left-standard-padding']}>
        <p className='font-ev-blue label-text'>Charging mode</p>
        <div className={styles.grid + ' grid grid-col-hd-33'}>
          <div className={styles['grid-item']}>
            <div>
              <h3 className='font-face-heavy'>GREEN</h3>
              <h3 className='font-face-thin'>In 3 hrs</h3>
            </div>
          </div>
          <div className={styles['grid-item']}>
            <div>
              <h3 className='font-face-heavy'>FAST</h3>
              <h3 className='font-face-thin'>In 1.5 hrs</h3>
            </div>
          </div>
          <div className={styles['grid-item'] + ' ' + styles.active}>
            <div>
              <h3 className='font-face-heavy'>SCHEDULE</h3>
              <h3 className='font-face-thin'>4-6 PM</h3>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ChargingModeComponent;
