import StorageItem from './StorageItem';
import * as dataUtils from '../../utils/dataUtils';

const STORAGE_PREFIX = 'nlycs.';

class DataStoreService {

    /**
     * @type {Storage}
     * @private
     */
    localStorage;


    /**
     * @type {{}}
     * @private
     */
    listeners = {};


    constructor(localStorage = window.localStorage) {

        this.localStorage = localStorage;
    }


    set(name, value, notify = true) {

        const storageItem = new StorageItem(name, JSON.stringify(value));

        this.localStorage.setItem(name, JSON.stringify(storageItem));

        if (notify) {
            this.notify(name, storageItem);
        }
    }


    get(name, defaultValue = null) {

        let storedItem = this.localStorage.getItem(name);

        if (!storedItem) {

            return defaultValue;
        }

        const storageItem = this.deserializeStorageItem(storedItem);

        return storageItem.getValue();
    }


    delete(name) {

        this.localStorage.removeItem(name);
    }


    addListener(name, listener) {

        if (this.listeners[name] === undefined) {
            this.listeners[name] = [];
        }

        this.listeners[name].push(listener);
    }


    deserializeStorageItem = (storedItem) => {

        const storageItem = new StorageItem();

        JSON.parse(storedItem, (key, value) => {

            if (storageItem.hasOwnProperty(key)) {
                storageItem[key] = value;
            }
        });

        return storageItem;
    };


    notify = (name, storageItem) => {

        let listeners = this.listeners[name];

        // no listeners or all removed - nothing to do
        if (!listeners || listeners.length < 1) {

            return;
        }

        // call each listeners
        listeners.forEach((listener) => listener(storageItem));
    };
    
    clear() {

        dataUtils.each(this.localStorage, (value, key) => {

            if (key.indexOf(STORAGE_PREFIX) === 0) {

                this.delete(key.replace(STORAGE_PREFIX, ''));
            }
        });
    }
}


export default DataStoreService;
