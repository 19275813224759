import {fromJS} from 'immutable';

import * as dataUtils from '../../../utils/dataUtils';
import * as metaStateUtils from '../../../store/util/metaStateUtils';


export default (state, locations) => {

    const locationsById = dataUtils.keyBy(locations, 'id');

    state = state.set('locationsById', fromJS(locationsById));

    return metaStateUtils.setIsLoaded(state);
};
