import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import styles from './HeaderComponent.scss';
import Summary from '../Summary';
import authService from '../../services/Auth';
import dataStoreService from '../../services/DataStore';

class HeaderComponent extends React.Component {

    static propTypes = {
        intl: PropTypes.object.isRequired,
        status: PropTypes.any.isRequired
    };


    handleClick = (event) => {
      console.log("I will logout");
      authService.logout(() => {
        try{
          dataStoreService.clear();
        }catch(e) {
          console.log("Ignoring error for now");
          console.log(e);
        }
        //window.location.reload();
      });
    }

    render() {
        return (
            <header className={styles.header + ' flex flex-space-between'}>
                <div className={styles.logo}>
                    <img src='images/logo.jpg' className={styles.logoimg} alt='logo' />
                </div>
                <div className={styles.topstatus + ' font-white padding-10 padding-bottom-0 text-left flex flex-align-bottom'}>
                    <a  onClick={this.handleClick} className={styles.logout}><img src='images/logout.png'/></a>
                    <div className={styles.textwrapper}>
                        <Summary heading='top.status' value={this.props.status} unit='' />
                    </div>
                </div>
            </header>
        );
    }
}


export default injectIntl(HeaderComponent);
