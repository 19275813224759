import {composeWithDevTools} from 'redux-devtools-extension';
import Immutable from 'immutable';

const composer = composeWithDevTools({
    serialize: {
        immutable: Immutable
    },
    features: {
        pause: false, // start/pause recording of dispatched actions
        lock: false, // lock/unlock dispatching actions and side effects
        persist: false, // persist states on page reloading
        export: false, // export history of actions in a file
        import: 'custom', // import history of actions from a file
        jump: true, // jump back and forth (time travelling)
        skip: false, // skip (cancel) actions
        reorder: false, // drag and drop actions in the history list
        dispatch: true, // dispatch custom actions or action creators
        test: false // generate tests for the selected actions
    },
    maxAge: 20, // Increase if needed, but beware of performance costs
    name: 'nlycs management web'
});

export default composer;
