export const each = (object, callback) => {

    Object.keys(object).forEach(function (key) {

        callback(object[key], key);
    });
};


export const map = (object, callback) => {

    const mapped = {};

    each(object, (value, key) => {

        mapped[key] = callback(value, key);
    });

    return mapped;
};


export const mapToArray = (object, callback) => {

    const mapped = map(object, callback);

    return Object.values(mapped);
};


export const merge = (object1, object2) => {

    const mergedObject = {...object1};

    each(object2, (value, key) => {

        if (value !== null && typeof value === 'object') {

            const existingValue = object1[key];

            value = merge(existingValue ? existingValue : {}, value);
        }

        mergedObject[key] = value;
    });

    return mergedObject;
};


export const flattenArray = (array, result = []) => {

    for (let i = 0, length = array.length; i < length; i++) {

        const value = array[i];
        if (Array.isArray(value)) {
            flattenArray(value, result);
        } else {
            result.push(value);
        }
    }

    return result;
};


export const isSomething = (variable) => {

    return typeof variable !== 'undefined' && variable !== null;
};


export const isDifferent = (object1, object2) => {

    if (typeof object1 !== typeof object2) {

        return true;
    }

    return JSON.stringify(object1) !== JSON.stringify(object2);
};


/**
 * @param objects {array|object[]} List of objects to key by the value of the given fieldName
 * @param fieldName {string} Field to key by
 */
export const keyBy = (objects, fieldName) => {

    const keyedBy = {};

    objects.forEach((object) => {

        keyedBy[object[fieldName]] = object;
    });

    return keyedBy;
};
