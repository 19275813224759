import {fromJS} from 'immutable';


import * as metaStateUtils from '../../../store/util/metaStateUtils';


export default (state, configuration) => {

    state = fromJS(configuration);
    state = metaStateUtils.setIsLoaded(state);

    return state;
};
