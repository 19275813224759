import React from 'react';

import authService from '../../../services/Auth';
import dataStoreService from '../../../services/DataStore';

import FullPageLoadingIndicator from '../../UI/LoadingIndicator/FullPageLoadingIndicator';


class LogoutComponent extends React.Component {

    componentDidMount() {
      console.log("I am loggingout out now");
        authService.logout(() => dataStoreService.clear());
    }


    render() {

        return <FullPageLoadingIndicator/>;
    }
}


export default LogoutComponent;
