import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styles from './StatusChartComponent.scss';
import Summary from '../Summary';

class StatusChartComponent extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired
  };
  render() {
    return (
      <div className={styles['status-chart']}>
        <div className='flex flex-space-between'>
          <div className={styles['vertical-chart']}>
            <div className={styles['chart-value']} style={{height: this.props.data.current_charge.value + '%'}} />
          </div>
          <ul className={styles['text-figures']}>
            <li>
              <Summary heading='status.status_of_charge' value={this.props.data.current_charge.value} unit={this.props.data.current_charge.unit} />
            </li>
            <li>
              <Summary heading='status.remaining_charge_time' value={this.props.data.remaining_charge_time.value} unit={this.props.data.remaining_charge_time.unit} />
            </li>
            <li>
              <Summary heading='status.charging_power' value={this.props.data.charging_power.value} unit={this.props.data.charging_power.unit} />
            </li>
          </ul>
        </div>
      </div >
    );
  }
}

export default StatusChartComponent;
