import * as apiConstants from '../apiConstants';

import * as uuidUtils from '../../../utils/uuidUtils';


class ApiRequest {

    /**
     * @type {string|null}
     * @private
     */
    body = null;

    /**
     * @type {{}}
     * @private
     */
    dispatches = {};

    /**
     * @type {{}}
     * @private
     */
    headers = {};

    /**
     * @type {string|null}
     * @private
     */
    method = null;

    /**
     * @type {string|null}
     * @private
     */
    path = null;

    /**
     * @type {{}}
     * @private
     */
    pathVariables = {};

    /**
     * @type {{}}
     * @private
     */
    queryParameters = {};

    /**
     * Final URL of the request
     * @type {null|string}
     * @private
     */
    url = null;

    /**
     * @type {string|*} Unique request identifier, UUID object
     */
    uuid = {};


    constructor(path, method = apiConstants.HTTP_METHOD_GET) {

        this.path = path;
        this.method = method;

        this.uuid = uuidUtils.generateUuid();

        this.dispatches[apiConstants.REQUEST_FAILURE] = [];
        this.dispatches[apiConstants.REQUEST_INIT] = [];
        this.dispatches[apiConstants.REQUEST_SUCCESS] = [];
    }


    /**
     * @param body
     */
    setBody(body) {

        this.body = body;
    }


    getBody() {

        return this.body;
    }


    /**
     * @param type {number|string} Any allowed dispatch type
     * @param dispatchCallback {function} Callback
     * @returns {ApiRequest}
     */
    addDispatch(type, dispatchCallback) {

        this.getDispatchesForType(type).push(dispatchCallback);
    }


    getDispatches(type) {

        return this.getDispatchesForType(type);
    }


    /**
     * @param type {number|string} Type to remove dispatches for
     */
    clearDispatches(type) {

        this.getDispatchesForType(type).length = 0;
    }


    /**
     * @param type {number|string} Type to get dispatches for
     * @returns {[]} List of registered dispatches
     */
    getDispatchesForType = (type) => {

        if (typeof type === 'number') {
            type = type.toString(10);
        }

        if (this.dispatches[type] === undefined) {
            this.dispatches[type] = [];
        }

        return this.dispatches[type];
    };


    getHeaders() {

        return this.headers;
    }


    getHeader(name, defaultValue) {

        if (this.headers.hasOwnProperty(name)) {

            return this.headers[name];
        }

        return defaultValue || null;
    }


    /**
     * @param name {string} Header name
     * @param value {string|number} Header value
     * @returns {ApiRequest}
     */
    setHeader(name, value) {
      console.log("Setting header " + name + ":" + value);
        this.headers[name] = value;
    }


    /**
     * @returns {string} HTTP request method
     */
    getMethod() {

        return this.method;
    }


    /**
     * @param method {string} HTTP request method
     */
    setMethod(method) {

        this.method = method;
    }


    getParsedPath() {

        let path = this.getPath();
        Object.keys(this.getPathVariables()).forEach((pathVariableName) => {

            path = path.replace(':' + pathVariableName, this.getPathVariable(pathVariableName));
        });

        return path;
    }


    /**
     * @return {string} Request path
     */
    getPath() {

        return this.path;
    }


    /**
     * @param path {string} Request path
     */
    setPath(path) {

        this.path = path;
    }


    getPathVariable(name, defaultValue) {

        if (this.pathVariables.hasOwnProperty(name)) {

            return this.pathVariables[name];
        }

        return defaultValue !== undefined ? defaultValue : null;
    }


    /**
     * @param name {string} Path variable name
     * @param value {string|number} Path variable value
     */
    setPathVariable(name, value) {

        this.pathVariables[name] = value;
    }


    getPathVariables() {

        return this.pathVariables;
    }


    /**
     * @param pathVariables {object} Key/value path variables (key->name, value->value)
     */
    setPathVariables(pathVariables) {

        this.pathVariables = pathVariables;
    }


    /**
     * @param name {string} Query parameter name to get value for
     * @param defaultValue {*} Value returned in case the name doesn't exist
     * @returns {*} Value of default value
     */
    getQueryParameter(name, defaultValue) {

        if (this.queryParameters.hasOwnProperty(name)) {

            return this.queryParameters[name];
        }

        return defaultValue !== undefined ? defaultValue : null;
    }


    /**
     * @param name {string} Query parameter name
     * @param value {string|number} Query parameter value
     */
    setQueryParameter(name, value) {

        this.queryParameters[name] = value;
    }


    /**
     * @returns {object|{}} All query parameters
     */
    getQueryParameters() {

        return this.queryParameters;
    }


    /**
     * @returns {string|null} Fully constructed URL
     */
    getUrl() {

        return this.url;
    }


    /**
     * @param url {string} Fully constructed url
     */
    setUrl(url) {

        this.url = url;
    }
}


export default ApiRequest;
