import developConfig from './config.develop';
import productionConfig from './config.production';

const configurations = {
    develop: developConfig,
    production: productionConfig,
    default: productionConfig
};

/**
 * Returns the configuration for the given identifier. Throws an error if the requested configuration does not exist.
 */
export default (configIdentifier) => {

    const configuration = configurations[configIdentifier];

    if (!configuration) {

        throw new Error('Configuration `' + configIdentifier + '` does not exist!');
    }

    return configuration;
};
