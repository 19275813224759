import React from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {fromJS} from 'immutable';
import {withRouter} from 'react-router-dom';


import * as oAuthUtils from '../../utils/oAuthUtils';

import * as authActions from './authActions';

import * as authSelectors from './authSelectors';

import authService from '../../services/Auth';



class AuthContainer extends React.Component {

    static propTypes = {
        children: PropTypes.any,
        history: PropTypes.object.isRequired,
        isAuthenticated: PropTypes.bool.isRequired,
        isAuthenticating: PropTypes.bool.isRequired,
        oAuthToken: PropTypes.object,
        onValidUser: PropTypes.func.isRequired
    };


    componentDidMount() {

        if (this.props.isAuthenticating && this.props.oAuthToken) {

            authService.setToken(this.props.oAuthToken);

            authService.validateAuthentication(::this.onValidUser, ::this.onInvalidUser);

            return;
        }

        if (!this.props.isAuthenticated) {

            authService.login();
        }
    }


    componentDidUpdate(prevProps) {

        if (this.props.isAuthenticated !== prevProps.isAuthenticated) {

            this.props.history.push('/');
        }
    }


    shouldComponentUpdate() {

        return true;
    }


    onValidUser(user) {

        this.props.onValidUser(user);
    }


    onInvalidUser() {

        // logout if no user-data can be fetched
        this.props.history.push('/logout');
    }


    render() {

        if (this.props.isAuthenticating || !this.props.isAuthenticated) {

            return null;
        }

        return this.props.children;
    }
}



const mapStateToProps = (state, props) => {

    const path = props.location.pathname;

    const urlOAuthToken = fromJS(oAuthUtils.extractOAuthTokenFromPathQueryString(path));
    const storedOAuthToken = authService.getToken();

    const isAuthenticated = authSelectors.isAuthenticated(state);
    let isAuthenticating = !!path.match(/^\/login\/success\/&access_token=.+/g);

    let currentOAuthToken = urlOAuthToken;
    // in case the oauth token is a stored one (e.g. after reload) authenticating is simulated
    if (!currentOAuthToken.get('access_token')) {

        currentOAuthToken = storedOAuthToken;

        if (!isAuthenticated) {
            isAuthenticating = true;
        }
    }

    return {
        isAuthenticated,
        isAuthenticating,
        oAuthToken: currentOAuthToken
    };
};


const mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators({...authActions}, dispatch)
});


const connectedAuth = connect(
    mapStateToProps,
    mapDispatchToProps
)(AuthContainer);


export default withRouter(connectedAuth);
