// ------ actions -------------------------------------------------------------

export const ACTION_USERS_LOADING = 'USERS_LOADING';

export const ACTION_USERS_LOADED = 'USERS_LOADED';

export const ACTION_USER_LOADING = 'USER_LOADING';

export const ACTION_USER_LOADED = 'USER_LOADED';

export const ACTION_USER_SAVED = 'USER_SAVED';



// ------ role constants

export const ROLE_ADMIN = 'admin';

export const ROLE_OPERATOR = 'operator';

export const ROLE_USER = 'user';

export const ROLE_GUEST = 'guest';


export const ROLES = [
    ROLE_OPERATOR,
    ROLE_USER,
    ROLE_GUEST
];
