import * as dataUtils from './dataUtils';


/**
 * Returns the application environment identifier. Using default if none is set to be used for local development
 *
 * @returns {*|string}
 */
export const getEnvironment = () => {

    const environment = process.env.NLYCS_ENVIRONMENT;
    if (!environment) {
        return "develop";
        //throw Error('No environment specified in env variable NLYCS_ENVIRONMENT');
    }

    return environment;
};


/**
 * Extracts keys beginning with the placeholder
 *
 * @param envHolder {object} Object containing variables to check for
 * @param prefix {string} Prefix to parse
 */
export const extractEnvironmentVariables = (envHolder, prefix = 'NLYCS_') => {

    const variables = {};

    dataUtils.each(envHolder, (value, key) => {

        if (key.indexOf(prefix) === 0) {
            variables[key] = value;
        }
    });

    return variables;
};
