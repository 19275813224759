const ACCESS_TOKEN = 'access_token';
const TOKEN_TYPE = 'token_type';
const EXPIRES_IN = 'expires_in';


export const extractOAuthTokenFromPathQueryString = (queryString) => {

    const params = {
        [ACCESS_TOKEN]: null,
        [TOKEN_TYPE]: null,
        [EXPIRES_IN]: null
    };

    Object.keys(params).forEach((parameterName) => {

        const regExp = new RegExp(parameterName + '=([^&]+)');
        const matches = queryString.match(regExp);

        if (matches && matches[1]) {
            params[parameterName] = matches[1];
        }
    });

    return params;
};
