import {Map} from 'immutable';

import * as authConstants from '../authConstants';

import authenticationSuccessfulReducer from './authenticationSuccessfulReducer';


const initialState = Map({
    isAuthenticated: false,
    user: null
});


function reducers(state = initialState, action) {

    switch (action.type) {


        case authConstants.ACTION_AUTH_SUCCESSFUL:

            return authenticationSuccessfulReducer(state, action.payload);


        default:

            return state;
    }
}

export default reducers;
