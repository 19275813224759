/**
 * Write all configuration options as parameters into the build
 */
export default {

    // Different endpoint configurations
    endpoints: {

        // APIs / resource server
        auth: {
            host: (process.env.NLYCS_OAUTH_URI || '')
        },

        // management backend
        management: {
            host: (process.env.NLYCS_PLATFORM_URI || '') + '/service/management'
        },

        // device data service
        deviceData: {
            host: (process.env.NLYCS_PLATFORM_URI || '') + '/service/device-data'
        }
    },


    // environment
    environment: {
        name: '', // will be set to the configIdentifier, hence the environment name
        isDevelopment: false,
        isProduction: false
    },


    host: {
        uri: (process.env.NLYCS_MANAGEMENT_WEB_URI || '')
    },


    locale: {

        available: [
            {
                locale: 'de-DE',
                name: 'Deutsch'
            },
            {
                locale: 'en-GB',
                name: 'English UK'
            }
        ],

        // in case no locale could be detected from the user agent, ultimately end up with this locale
        default: 'de-DE'
    },


    // OAuth configuration
    oauth: {
        clientId: 'smart-charging-app',
        scope: [
            'read',
            'write'
        ],
        uri: (process.env.NLYCS_OAUTH_URI || '')
    },


    time: {

        timeZone: {

            // used in case no timezone id could be determined
            default: 'UTC'
        }
    }
};
