import React, {Component} from 'react';
import ConsumptionLineChartComponent from './ConsumptionLineChartComponent';
import PropTypes from 'prop-types';
import styles from './ConsumptionComponent.scss';
import Summary from '../Summary';

class ConsumptionComponent extends Component {
  static propTypes = {
    data: PropTypes.any.isRequired
  }
  render() {
    return (
      <div className={styles['consumption-component']}>
        <div className='flex flex-space-between flex-align-middle'>
          <ul className={styles['text-figures'] + ' flex-1'}>
            <li>
              <div className={styles['figure-item']}>
                <Summary editable='true' heading='consumption.estimated_cruising_range' value={this.props.data.estimated_cruising_range.value} unit={this.props.data.estimated_cruising_range.unit} valueChanged={this.props.valueChanged}/>
              </div>
              <div className={styles['figure-item']}>
                <Summary heading='consumption.charging_costs' value={this.props.data.charging_costs.value} unit={this.props.data.charging_costs.unit} />
              </div>
            </li>
            <li>
              <div className={styles['figure-item']}>
                <Summary heading='consumption.avg_consumption' value={this.props.data.avg_consumption.value} unit={this.props.data.avg_consumption.unit} />
              </div>
            </li>
          </ul>
          <div className={styles['consumption-linechart']}>
            <ConsumptionLineChartComponent />
          </div>
        </div>
      </div>
    );
  }
}

export default ConsumptionComponent;
