import React from 'react';
import PropTypes from 'prop-types';
import {addLocaleData, IntlProvider} from 'react-intl';

import config from '../../config';

import dataStoreService from '../../services/DataStore';


class I18nComponent extends React.Component {

    static propTypes = {
        children: PropTypes.any
    };

    state = {
        locale: null,
        message: {}
    };


    componentDidMount() {

        const detectedLocale = I18nComponent.detectLocale();

        this.onChangeLocale(detectedLocale);

        // listen for changes
        dataStoreService.addListener('locale', (storageItem) => {

            ::this.onChangeLocale(storageItem.getValue());
        });
    }


    static detectLocale() {

        // is there a stored locale?
        let storedLocale = dataStoreService.get('locale');

        if (storedLocale !== null) {

            return storedLocale;
        }

        // if the backend provides a default language during boot - read it here
        let providedLocale = null;

        return providedLocale
            || (navigator.languages && navigator.languages[0])
            || navigator.language
            || navigator.browserLanguage
            || config.locale.default;
    }


    static getBaseLocale(locale) {

        return locale.substring(0, 2);
    }


    onChangeLocale(newLocale) {

        const baseLocale = I18nComponent.getBaseLocale(newLocale);

        // loading intl-data (formatting etc.)
        const newLocaleData = require(`react-intl/locale-data/${baseLocale}`);
        addLocaleData([...newLocaleData]);

        // loading translations
        const messages = require(`../../data/${baseLocale}`);

        dataStoreService.set('locale', newLocale, false);

        this.setState({
            locale: newLocale,
            messages: messages
        });
    }



    render() {

        const {locale, messages} = this.state;

        if (!locale) {

            return null;
        }

        return (
            <IntlProvider key={locale}
                          locale={locale}
                          messages={messages}
            >

                {this.props.children}

            </IntlProvider>
        );
    }
}


export default I18nComponent;
