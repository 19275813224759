import React, {Component} from 'react';
import './ConsumptionLineChartComponent.scss';

class ConsumptionLineChartComponent extends Component {
  render() {
    return (
      <div>
        <img src='images/line-chart-demo.png' alt='chart demo' />
      </div>
    );
  }
}

export default ConsumptionLineChartComponent;
