import React from 'react';
import PropTypes from 'prop-types';
import {FormattedHTMLMessage, FormattedMessage} from 'react-intl';


class I18nMessageComponent extends React.PureComponent {

    static propTypes = {
        children: PropTypes.any,
        defaultMessage: PropTypes.string,
        isHtml: PropTypes.bool,
        id: PropTypes.string.isRequired,
        values: PropTypes.object
    };

    static defaultProps = {
        isHtml: false
    };


    render() {

        const translation = this.renderTranslation();

        if (this.props.children) {

            return this.props.children(translation);
        }

        return translation;
    }


    renderTranslation() {

        const I18nComponentClass = this.props.isHtml ? FormattedHTMLMessage : FormattedMessage;

        return (

            <I18nComponentClass defaultMessage={this.props.defaultMessage || this.props.id}
                                id={this.props.id}
                                values={this.props.values}
            >
                {(message) => {

                    return <React.Fragment>{message}</React.Fragment>;
                }}
            </I18nComponentClass>
        );
    }
}


export default I18nMessageComponent;
