export default {

    endpoints: {

        auth: {
            host: 'https://auth.nlycs.com'
        },

        management: {
            host: ''
        },
        
        backend: {
            host: 'https://dev-api.nlycs.com'
        }
    }
};
