import {fromJS, Map} from 'immutable';
import {DateTime} from 'luxon';


const KEY_META = '_meta';
const KEY_IS_LOADED = 'isLoaded';
const KEY_IS_LOADING = 'isLoading';
const KEY_IS_UPDATING = 'isUpdating';
const KEY_LOAD_TIME = 'loadTime';


const getMetaState = (state) => {

    return state.get(KEY_META, Map());
};


const setMetaState = (state, metaState) => {

    return state.set(KEY_META, metaState);
};


export const getDefaultMetaState = () => {

    return fromJS({
        _meta: {
            isLoaded: false,
            isLoading: false,
            isUpdating: false,
            loadTime: null
        }
    });
};


/**
 * @param {Map} state State with meta information
 * @returns {boolean} True if loaded; false if not
 */
export const isLoaded = (state) => {

    return getMetaState(state)
        .get(KEY_IS_LOADED, false);
};


/**
 * @param {Map} state State with meta information
 * @returns {boolean} True if loading; false if not
 */
export const isLoading = (state) => {

    return getMetaState(state)
        .get(KEY_IS_LOADING, false);
};


/**
 * @param {Map} state State with meta information
 * @returns {boolean} True if loading; false if not
 */
export const isUpdating = (state) => {

    return getMetaState(state)
        .get(KEY_IS_UPDATING, false);
};


/**
 * @param {Map} state State with meta information
 * @returns {boolean} True if neither loading nor loaded; false if any of those is active
 */
export const isMissing = (state) => {

    return !isLoading(state) && !isLoaded(state);
};


/**
 * @param {Map} state State with meta information
 * @returns {Map} State in loading state
 */
export const setIsLoading = (state) => {

    let metaState = getMetaState(state);

    metaState = metaState.set(KEY_IS_LOADING, true);

    return setMetaState(state, metaState);
};

/**
 * @param {Map} state State with meta information
 * @returns {Map} State in loading state
 */
export const setIsUpdating = (state) => {
    let metaState = getMetaState(state);

    metaState = metaState.set(KEY_IS_UPDATING, true);

    return setMetaState(state, metaState);
};


/**
 * @param {Map} state State with meta information
 * @returns {Map} State in loaded state
 */
export const setIsLoaded = (state) => {

    let metaState = getMetaState(state)
        .set(KEY_IS_LOADING, false)
        .set(KEY_IS_LOADED, true)
        .set(KEY_IS_UPDATING, false)
        .set(KEY_LOAD_TIME, DateTime.utc());

    return setMetaState(state, metaState);
};
