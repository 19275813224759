import React from 'react';
import PropTypes from 'prop-types';
import I18nMessage from '../I18n/I18nMessage';

import styles from './SummaryComponent.scss';

function contentEditable(WrappedComponent, valueChangeCallback) {

  return class extends React.Component {

    state = {
      editing: false
    }

    toggleEdit = (e) => {
      e.stopPropagation();
      if (this.state.editing) {
        this.cancel();
      } else {
        this.edit();
      }
    };

    edit = () => {
      this.setState({
        editing: true
      }, () => {
        this.domElm.focus();
      });
    };

    save = () => {
      this.setState({
        editing: false
      }, () => {
        if (this.isValueChanged()) {
          console.log('Value is changed', this.domElm.textContent);
          valueChangeCallback(this.domElm.textContent);
        }
      });
    };

    cancel = () => {
      this.setState({
        editing: false
      });
    };

    isValueChanged = () => {
      return this.props.value !== this.domElm.textContent;
    };

    handleKeyDown = (e) => {
      const {key} = e;
      switch (key) {
        case 'Enter':
        case 'Escape':
          this.save();
          break;
        default:
          break;
      }
    };

    render() {
      let editOnClick = true;
      const {editing} = this.state;
      if (this.props.editOnClick !== undefined) {
        editOnClick = this.props.editOnClick;
      }
      return (
        <WrappedComponent
          className={editing ? 'editing' : ''}
          onClick={editOnClick ? this.toggleEdit : undefined}
          contentEditable={editing}
          ref={(domNode) => {
            this.domElm = domNode;
          }}
          onBlur={this.save}
          onKeyDown={this.handleKeyDown}
          {...this.props}
      >
        {this.props.value}
      </WrappedComponent>
      );
    }
  };
}

class SummaryComponent extends React.Component {

  static propTypes = {
    heading: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    unit: PropTypes.any.isRequired
  };

  render() {
    let heading;
    if(this.props.editable) {
      let EditableSpan = contentEditable('span', this.props.valueChanged);
      heading = <h3 className={styles.valuetext + ' font-face-thin font-white'}>
                  <EditableSpan value={this.props.value} />{this.props.unit}
                </h3>;
    }else {
      heading = <h3 className={styles.valuetext + ' font-face-thin font-white'}>
          {this.props.value}{this.props.unit}
          </h3>;
    }
    return (
      <React.Fragment>
        <p className={styles.labeltext + ' font-ev-blue'}>
          <I18nMessage id={this.props.heading} />
        </p>
      {heading}
      </React.Fragment>
    );
  }
}


export default SummaryComponent;
