import React from 'react';
import PropTypes from 'prop-types';


import Header from '../Header';
//import SourceChartComponent from '../SourceChartComponent';
import StatusChartComponent from '../StatusChartComponent';
import ConsumptionComponent from '../ConsumptionComponent';
import ChargingModeComponent from '../ChargingModeComponent';

import styles from './AppComponent.scss';
import SourceChartComponent from '../SourceChartComponent';
import NavigationComponent from '../NavigationComponent';

import apiService from '../../services/Api';
import authService from '../../services/Auth';
import ApiRequestBuilder from '../../services/Api/Request/ApiRequestBuilder';

const ENDPOINT_DEVICE_DATA = '/devices/E3DC_S10E5/values?key=PV_Power,Bat_Power,Wallbox_0_Ctrl,Wallbox_Power,Wallbox_PV_Power&limit=1';
class AppComponent extends React.Component {

  state = {
    data: {
      status: 'Not Charging',
      status_data: {
        current_charge: {
          value: 75,
          unit: '%'
        },
        remaining_charge_time: {
          value: 23,
          unit: ' min'
        },
        charging_power: {
          value: 0,
          unit: ' KW'
        }
      },
      consumption_data: {
        estimated_cruising_range: {
          value: 100,
          unit: ' km'
        },
        charging_costs: {
          value: 2.57,
          unit: ' $'
        },
        avg_consumption: {
          value: 14.3,
          unit: 'kWh/100km'
        }
      },
      battery_consumption: [
        {
          label: 'Home Battery',
          value: 0
        }
      ]
    }
  }

  static propTypes = {
    children: PropTypes.any
  };

  parseResponseData = (deviceData) => {
    console.log("Response data");
    console.log(deviceData);
    var data = this.state.data;
    data.battery_consumption = [];
    deviceData.map(function (d) {
      switch (d.key) {
        case 'Wallbox_0_Ctrl':
          if ((d.value >> 3) % 2 === 0) { //eslint-disable-line no-bitwise
            data.status = 'Not Charging';
          } else {
            data.status = 'Charging';
          }
          break;
        case 'Wallbox_Power':
          data.status_data.charging_power = {
            value: d.value,
            unit: ' ' + d.unit
          };
          data.battery_consumption.push({
            label: 'Grid',
            value: d.value
          });
          break;
        case 'Wallbox_PV_Power':
          data.battery_consumption.push({
            label: 'Home+PV',
            value: d.value
          });
          break;
        default:
          break;
      }
    });
    this.setState({
      data: data
    });
  }

  onDeviceDataLoaded = (response) => {
    console.log('Success');
    console.log(response);
    this.parseResponseData(response);
  }

  onDeviceDataLoadFailure = (response) => {
    console.log('Failure');
    window.location.reload();
  }

  fetchDeviceData = () => {
    const token = authService.getToken();
    console.log(token);
    if(!token) {
      this.onDeviceDataLoadFailure();
    }
    const apiRequest = ApiRequestBuilder.get(ENDPOINT_DEVICE_DATA, "deviceData")
      .json()
      .onSuccess((dispatch, request, response) => this.onDeviceDataLoaded(response.getBody()))
      .onStatus(401, this.onDeviceDataLoadFailure)
      .onFailure((dispatch, request, response) => {
        console.log("Failed");
        this.onDeviceDataLoadFailure(response);
      })
      .header("Authorization", "Bearer " + token.access_token)
      .build();
    apiService.execute(apiRequest);
  }

  componentDidMount() {
    this.fetchDeviceData();
    this.timer = setInterval(() => this.fetchDeviceData(), 30000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    this.timer = null;
  }

  getCurrentCharge = (cruisingRange) => {
    return parseInt(cruisingRange * 100 / 130, 10);
  }

  getAvgComsumptionValue = (crisingRange) => {
    return (100 * 21.6 / 130).toFixed(1);
  }

  getRemainingChargeTime = (currentCharge) => {
    return parseInt((6 / 100) * (100 - currentCharge) * 60, 10);
  }

  updateInferredValues = (cruisingRange) => {
    var data = this.state.data;
    data.consumption_data.estimated_cruising_range.value = cruisingRange;
    data.status_data.current_charge.value = this.getCurrentCharge(cruisingRange);
    data.consumption_data.avg_consumption.value = this.getAvgComsumptionValue(cruisingRange);
    data.status_data.remaining_charge_time.value = this.getRemainingChargeTime(data.status_data.current_charge.value);
    this.setState({
      data: data
    });
  }

  onCruisingRangeSet = (value) => {
    this.updateInferredValues(value);
  }

  render() {

    return (

      <React.Fragment>

        <div className={styles.wrapper}>
          <div className={styles.App}>
            <Header status={this.state.data.status} />
            <div className='flex flex-space-between flex-align-middle'>
              <SourceChartComponent data={this.state.data.battery_consumption} />
              <StatusChartComponent data={this.state.data.status_data} />
            </div>
            <div>
      <ConsumptionComponent data={this.state.data.consumption_data} valueChanged={this.onCruisingRangeSet}/>
            </div>
            <ChargingModeComponent />
          </div>
          <NavigationComponent />
        </div>

      </React.Fragment>
    );
  }
}

export default AppComponent;

