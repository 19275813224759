import React from 'react';
import PropTypes from 'prop-types';

import I18nMessage from '../../../I18n/I18nMessage/I18nMessageComponent';

import styles from './FullPageLoadingIndicatorComponent.scss';


class FullPageLoadingIndicatorComponent extends React.Component {

    static propTypes = {
        children: PropTypes.any
    };


    render() {

        return (
            <div className={styles.loadingIndicatorOverlay}>

                {this.renderContent()}

            </div>
        );
    }


    renderContent() {

        if (this.props.children) {

            return this.props.children;
        }

        return (
            <div>
                <p className={'icon-gear'}>
                    <I18nMessage id={'loading.full.text'}/>
                </p>
            </div>
        );
    }
}


export default FullPageLoadingIndicatorComponent;
