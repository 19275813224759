import fetch from 'isomorphic-fetch';

import * as apiConstants from './apiConstants';

import ApiResponse from './Response/ApiResponse';


class ApiService {

    /**
     * @type {object|store} Redux store binding
     */
    store;


    /**
     * @param store {object|store} Redux store to set binding to
     */
    setStore(store) {

        this.store = store;
    }


    /**
     * @param request {ApiRequest} Prepared API request
     */
    execute(request) {

        // dispatch init as we want to know what requests have been issued
        this.handleInjectedDispatches(apiConstants.REQUEST_INIT, request);

        const url = request.getUrl();
        const fetchConfig = this.createFetchConfig(request);
        fetch(url, fetchConfig)
            .then((fetchResponse) => {

                return this.handleResponse(fetchResponse, request);
            })
            .catch((error) => {

                // dispatch init as we want to know what requests have been issued
                this.handleInjectedDispatches(apiConstants.REQUEST_FAILURE, request, null, error);
            });
    }


    /**
     * @param request {ApiRequest}
     * @returns {{headers: {}, method: *}}
     * @private
     */
    createFetchConfig = (request) => {

        const requestConfig = {
            headers: request.getHeaders(),
            method: request.getMethod()
        };

        let body = request.getBody();
        if (body !== null) {

            // if needed, introduce multiple data-types here
            if (this.isJsonContentType(request)) {
                body = JSON.stringify(body);
            }

            requestConfig.body = body;
        }

        return requestConfig;
    };


    /**
     * @param fetchResponse {Response} WebAPI response
     * @param request {ApiRequest} Response triggered request
     * @private
     */
    handleResponse = (fetchResponse, request) => {

        let response = new ApiResponse(fetchResponse.status);

        // extract headers
        fetchResponse.headers.forEach((value, name) => {
            response.setHeader(name, value);
        });

        fetchResponse.text()
            .then((responseBody) => {

                response.setBody(responseBody);

                if (!this.isJsonContentType(response) || !response.hasBody()) {

                    return this.processResponse(request, response);
                }

                // parse JSON response
                (new Response(responseBody)).json()
                    .then((jsonBody) => {

                        response.setBody(jsonBody);

                        this.processResponse(request, response);
                    });
            });
    };


    /**
     * @param request {ApiRequest}
     * @param response {ApiResponse|null}
     * @private
     */
    processResponse = (request, response) => {

        // general successful dispatching
        if (this.isResponseSuccessful(response)) {

            this.handleInjectedDispatches(apiConstants.REQUEST_SUCCESS, request, response);
        }

        // otherwise dispatch on status callback
        this.handleInjectedDispatches(response.getStatusCode(), request, response);
    };


    /**
     * @param response {ApiResponse}
     * @returns {boolean} True if successful;
     * @private
     */
    isResponseSuccessful = (response) => {

        let statusCode = response.getStatusCode();

        return statusCode >= 200 && statusCode < 300;
    };


    /**
     * @param requestResponse {ApiRequest|ApiResponse}
     * @returns {boolean} True if application/json contained in Content-Type header; false if not
     */
    isJsonContentType = (requestResponse) => {

        const responseContentType = requestResponse.getHeader(apiConstants.HTTP_HEADER_CONTENT_TYPE);

        return responseContentType && responseContentType.indexOf(apiConstants.HTTP_HEADER_CONTENT_TYPE_JSON) !== -1;
    };


    /**
     * @param type {number|string} Dispatch type
     * @param request {ApiRequest} Issuing request
     * @param response {ApiResponse|null} Possible response
     * @param error {TypeError|Error|string|null} Possible error
     * @private
     */
    handleInjectedDispatches = (type, request, response = null, error = null) => {

        const store = this.store;

        request.getDispatches(type).forEach((callback) => {

            callback(store.dispatch, request, response, error);
        });
    };
}


export default ApiService;
