import * as authConstants from './authConstants';


export const onValidUser = (user) => {

    return {
        payload: user,
        type: authConstants.ACTION_AUTH_SUCCESSFUL
    };
};
