export default {

    endpoints: {

        auth: {
            host: 'https://dev-ic.nlycs.com/auth'
        },

        management: {
            host: 'https://dev-ic.nlycs.com/service/management'
        },

        // device data service
        deviceData: {
            host: 'https://dev-ic.nlycs.com/service/device-data'
        }
    },


    environment: {
        isDevelopment: true
    },


    host: {
        uri: 'http://localhost:3000'
    },


    oauth: {
        uri: 'https://dev-ic.nlycs.com/auth'
    }
};
