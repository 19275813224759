class StorageItem {

    /**
     * @type {string}
     */
    name;

    /**
     * @type {string} Serialized data container
     */
    value;


    /**
     * @type {number}
     */
    createTime;


    constructor(name, value) {

        this.name = name;
        this.value = value;
        this.createTime = new Date().getTime();
    }


    getName() {

        return this.name;
    }


    getValue() {

        return JSON.parse(this.value);
    }
}


export default StorageItem;
