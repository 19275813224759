import * as apiConstants from '../Api/apiConstants';

import config from '../../config';

import userService from '../User';

import OAuthTokenRequestBuilder from '../Api/Request/OAuthTokenRequestBuilder';


const API_PATH_LOGOUT = '/api/logout';


class AuthService {

    /**
     * @type {ApiService}
     * @private
     */
    apiService;

    /**
     * @type {DataStoreService}
     * @private
     */
    dataStoreService;

    /**
     * @type {string}
     * @private
     */
    tokenName = 'authToken';


    /**
     * @param apiService {ApiService}
     * @param dataStoreService {DataStoreService}
     */
    constructor(apiService, dataStoreService) {

        this.apiService = apiService;
        this.dataStoreService = dataStoreService;
    }


    /**
     * @return {object|null} Access token object or null
     */
    getToken() {

        return this.dataStoreService.get(this.tokenName, null);
    }


    setToken(token) {

        if (token === null) {

            return this.dataStoreService.delete(this.tokenName);
        }

        this.dataStoreService.set(this.tokenName, token);
    }


    /**
     * @returns {boolean} True if logged in; false if not
     */
    isLoggedIn() {

        return this.getToken() !== null;
    }


    /**
     * Logging in is simple -> directly redirecting to the OAuth authorization server
     */
    login() {

        window.location.href = this.constructOAuthAuthorizationUrl();
    }


    logout(onFinish = () => {}) {

        // execute HEAD logout
        const token = this.getToken();

        // no token present, directly process logout
        if (!token) {

            return this.executeLogout(onFinish);
        }

        const apiRequest = OAuthTokenRequestBuilder.head(API_PATH_LOGOUT, apiConstants.ENDPOINT_AUTH)
            .onSuccess(() => ::this.executeLogout(onFinish))
            .onFailure(() => ::this.executeLogout(onFinish))
            .build();

        // overwrite 401 logout redirect
        apiRequest.clearDispatches(apiConstants.HTTP_STATUS_UNAUTHORIZED);
        apiRequest.addDispatch(apiConstants.HTTP_STATUS_UNAUTHORIZED, () => this.executeLogout(onFinish));
      
        return this.apiService.execute(apiRequest);
    }


    executeLogout = (onFinish) => {

        this.removeAuthentication();

        onFinish();

        window.location.href = this.constructLogoutUrl();
    };

    
    /**
     * Validates the current authentication using the locally persisted token
     *
     * @param onSuccess {function} On successful validation
     * @param onFailure {function} On failed validation
     */
    validateAuthentication(onSuccess = () => {}, onFailure = () => {}) {

        const token = this.getToken();

        console.log("Token ");
        console.log(token);
        // no token - not authentication
        if (!token) {

            return this.login();
        }

        userService.getUserDetails(
            (userData) => {

                this.setToken(token);

                onSuccess(userData);
            },

            () => {

                this.removeAuthentication();

                onFailure();
            }
        );
    }


    /**
     * Validates the given token
     *
     * @param token {string} Token to validate
     * @param onSuccess {function} On successful validation
     * @param onFailure {function} On failed validation
     */
    validateToken(token, onSuccess = () => {}, onFailure = () => {}) {

        this.setToken(token);

        this.validateAuthentication(onSuccess, onFailure);
    }


    /**
     * @return {string} Full qualified OAuth authorization URL
     * @private
     */
    constructOAuthAuthorizationUrl = () => {

        const redirectUri = this.constructRedirectUri();
        const scope = config.oauth.scope.join('+');

        return config.endpoints.auth.host + '/oauth/authorize?response_type=token'
            + '&client_id=' + config.oauth.clientId
            + '&scope=' + scope
            + '&redirect_uri=' + window.encodeURIComponent(redirectUri);
    };


    /**
     * Returns the successful login URL
     *
     * @return {string} Redirect URI to provide for OAuth authorization
     * @private
     */
    constructRedirectUri = () => {

        const location = window.location;

        let redirectUri = location.protocol.concat('//')
            .concat(location.hostname);

        const port = location.port;
        if (port !== 80 || port !== 443) {
            redirectUri = redirectUri.concat(':' + port);
        }

        return redirectUri + '/#/login/success/';
    };

    /**
     * @returns {string} Logout URL to redirect the browser to
     */
    constructLogoutUrl = () => {

        const ownHost = window.location.protocol + '//'
            + window.location.hostname
            + (window.location.port ? ':' + window.location.port : '');

        return config.oauth.uri + '/logout?from=' + window.encodeURIComponent(ownHost);
    };

    
    /**
     * @private
     */
    removeAuthentication = () => {

        this.setToken(null);
    }
}


export default AuthService;
