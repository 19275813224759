import React from 'react';
import PropTypes from 'prop-types';
import {Provider} from 'react-redux';
import {HashRouter, Route, Switch} from 'react-router-dom';
import {hot} from 'react-hot-loader';

import App from './App';
import Auth from './Auth';
import I18n from './I18n';
import Logout from './Auth/Logout';

class RootComponent extends React.Component {
  static propTypes = {
    store: PropTypes.object.isRequired
  };
  render() {
    return (
      <Provider store={this.props.store}>
        <I18n>
          <HashRouter>
            <Auth>
              <App>
                <Switch>
                  <Route component={App}
                         exact={true}
                         path={'/'}
                  />
                  <Route component={Logout}
                         path={'/logout'}
                  />
                </Switch>
              </App>
            </Auth>
          </HashRouter>
        </I18n>
      </Provider>
    );
  }
}


export default hot(module)(RootComponent);
