import {combineReducers} from 'redux-immutable';

import authReducers from '../../components/Auth/reducer';
import configurationReducers from '../../components/Configuration/reducer';
import locationReducers from '../../components/Location/reducer';


const reducers = {

    auth: authReducers,

    configuration: configurationReducers,

    location: locationReducers
};


export default combineReducers(reducers);
