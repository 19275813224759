import * as dataUtils from '../../../utils/dataUtils';


class ApiResponse {

    /**
     * @type {any|null}
     * @private
     */
    body = null;

    /**
     * @type {object}
     * @private
     */
    headers = {};

    /**
     * @type {number}
     * @private
     */
    statusCode;


    /**
     * @param statusCode {number} Http status code of the response
     */
    constructor(statusCode) {

        this.statusCode = statusCode;
    }


    /**
     * @returns {any} Body
     */
    getBody() {

        return this.body;
    }


    /**
     * @param body {*} Body
     */
    setBody(body) {

        this.body = body;
    }


    /**
     * @returns {boolean} True if at body is defined
     */
    hasBody() {

        return !!this.body;
    }


    /**
     * @param name {string} Http header name
     * @param defaultValue {any|null} Default value returned in case there is no header for the given name
     */
    getHeader(name, defaultValue = null) {

        let headerName = name.toLowerCase();
        if (this.headers.hasOwnProperty(headerName)) {

            return this.headers[headerName];
        }

        return defaultValue;
    }


    /**
     * @param name {string} Http header name
     * @param value {string} Http header value
     */
    setHeader(name, value) {

        this.headers[name.toLowerCase()] = value;
    }


    /**
     * @returns {object} Http header name to value object
     */
    getHeaders() {

        return this.headers;
    }


    /**
     * @param headers {object} Http header name to value object
     */
    setHeaders(headers) {

        dataUtils.each(headers, (value, name) => {

            this.headers[name.toLowerCase()] = value;
        });
    }


    /**
     * @returns {number} Http status code
     */
    getStatusCode() {

        return this.statusCode;
    }
}


export default ApiResponse;
